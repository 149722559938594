import { t } from 'i18next';
import { AccentedContentCard } from '../../../atoms/ContentCard';
import { MainPageContent } from '../../../i18n/mainPageContent';
import styled from 'styled-components';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { ThemeOptions } from '../../../contexts/DarkModeContext';
import tooloboxSVG from './toolbox.svg';

const whiteHammerIcon = tooloboxSVG;

const UnderConstructionToastCard = styled(AccentedContentCard)`
  ${(_) => `
visibility: hidden;
transition: opacity 0.3s, color 0.3s, background 0.3s, width 0.3s, padding 0.3s ease-in-out;
transition visibility 0.3 ease-out;
position: absolute;
width: 400px;
left: 0px;
opacity: 0;
`};
`;

const UnderConstructionToastContainer = styled.div`
  height: 60px;
  position: relative;
  & img:hover + ${UnderConstructionToastCard} {
    visibility: visible;
    opacity: 1;
  }

  & img:hover {
    opacity: 0.6;
  }
`;

export const UnderConstructionWidget: React.FC<{}> = () => {
  const { theme } = useDarkMode();
  return (
    <UnderConstructionToastContainer>
      <img
        src={theme === ThemeOptions.dark ? whiteHammerIcon : whiteHammerIcon}
        alt="AriaGithubProfile"
        aria-label="Aria Github Profile Link"
        style={{
          filter: `${theme === ThemeOptions.dark ? 'invert(1)' : 'invert(0)'}`,
          width: '24px',
          height: '24px',
          padding: '5px'
        }}
      />

      <UnderConstructionToastCard>
        <h3>{t(MainPageContent.underConstructionTitle, { ns: ['main'] })}</h3>
        {t(MainPageContent.underConstructionBlurb, { ns: ['main'] })}
      </UnderConstructionToastCard>
    </UnderConstructionToastContainer>
  );
};

export default UnderConstructionWidget;
