import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ToggleSwitch from '../../atoms/ToggleSwitch';
import { FlexContainer } from '../../Layout';
import { ContactBar } from './ContactBar';
import UnderConstructionWidget from './UnderConstructionWidget/UnderConstructionWidget';

interface TopBarProps {}

const TopBarFlexContainer = styled(FlexContainer)`
  flex-direction: row-reverse;
  align-items: center;
  right: 0;
  top: 0;
  z-index: 99;
  position: fixed;
  align-content: 'center';
  height: 60px;
`;

export const TopBar: React.FC<TopBarProps> = () => {
  const { i18n } = useTranslation(['main']);
  const [checked, setChecked] = useState(true);

  const onClick = (checked: boolean) => {
    i18n.changeLanguage(checked === true ? 'en' : 'fr');
    setChecked(checked);
  };

  return (
    <div style={{ justifyContent: 'space-between' }}>
      <UnderConstructionWidget />
      <TopBarFlexContainer>
        <ToggleSwitch
          id="switch"
          name="switch"
          checked={checked}
          optionLabels={['🇬🇧', '🇫🇷']}
          onChange={(checked: any) => onClick(checked)}
        />
        <ContactBar />
      </TopBarFlexContainer>
    </div>
  );
};

export default TopBar;
