import React from 'react';
import { FlexContainer } from '../Layout';
import { ContactForm } from './ContactForm/ContactForm';
import { HomePage } from './Home';
import styled from 'styled-components';

const MainContentContainer = styled.div`
alignContent: 'center',
width: 'inherit'
padding-top: 60px;
flex: 90%;
`;

export const MainContent: React.FC = () => {
  return (
    <FlexContainer>
      <div style={{ flex: '5%' }}></div>
      <MainContentContainer>
        <HomePage />

        <ContactForm />
      </MainContentContainer>
      <div style={{ flex: '5%' }}></div>
    </FlexContainer>
  );
};
