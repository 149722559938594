import { MainPageContentStrings } from '../mainPageContent';

export const FrenchMainPageTranslation: Record<MainPageContentStrings, string> = {
  landingPageTitle: "Salut, c'est Aria 🍂",
  landingPageSubtitle: 'Parfois une artiste, une analyste, ou auteure, mais toujours absurde',
  underConstructionTitle: '🚧 Site toujours sous construction! 🚧',
  underConstructionBlurb:
    "Veuillez revenir de temps en temps! J'échange les widgets par à-coups pour mieux representer mon travail courant. Vous pouvez aussi me contacter en utilisant les liens sociaux à droite de l'écran, ou au bas de la page.",
  checkOutMyBlog:
    "J'ecrit des histoires fictionelles, analyse la literature, la musique, et les jeux videos, et fait des investigations sur les technologies que j'utilise sur mon blog que vous pouvez lire ici:",
  currentlyReading: "Voilà ce que j'ai lu récemment",
  currentlyWorkingTitle: 'Je suis en train de jouer avec'
};
