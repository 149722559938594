import React from 'react';
import { useTranslation } from 'react-i18next';
import { MainPageContent } from '../i18n/mainPageContent';
import { FlexContainer } from '../Layout';
import ContentCard from '../atoms/ContentCard';
import { BookWidget } from './BooksWidget/BooksWidget';
import './Home.css';
import BlogWidget from './BlogWidget/BlogWidget';
import CurrentActivityWidget from './CurrentActivityWidget/CurrentActivityWidget';
import styled from 'styled-components';

const WidgetsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const HomePage: React.FC<{}> = () => {
  const { t } = useTranslation(['main']);

  return (
    <>
      <FlexContainer>
        <ContentCard style={{ fontFamily: 'Satisfy-Regular', width: '100%' }}>
          <div>
            <h2>{t(MainPageContent.landingPageTitle, { ns: ['main'] })}</h2>
          </div>
          <div>
            <p>{t(MainPageContent.landingPageSubtitle, { ns: ['main'] })}</p>
          </div>
        </ContentCard>
      </FlexContainer>
      <WidgetsContainer>
        <div style={{ flex: '50%' }}>
          <BlogWidget />
          <BookWidget />
        </div>
        <div style={{ flex: '50%' }}>
          <CurrentActivityWidget />
        </div>
      </WidgetsContainer>
    </>
  );
};
