import { animated, useSpring } from '@react-spring/web';
import { ThemeOptions } from '../../contexts/DarkModeContext';
import { useDarkMode } from '../../hooks/useDarkMode';

const properties = {
  dark: {
    r: 9,
    transform: 'rotate(20deg)',
    cx: 4,
    cy: 6,
    opacity: 0
  },
  light: {
    r: 5,
    transform: 'rotate(180deg)',
    cx: 30,
    cy: 30,
    opacity: 0.7
  },
  springConfig: { mass: 4, tension: 250, friction: 35 }
};

export const SvgThemeToggle: React.FC = () => {
  const { theme, setTheme } = useDarkMode();

  const themeToggle = () => {
    setTheme(theme === ThemeOptions.dark ? ThemeOptions.light : ThemeOptions.dark);
  };
  const { r, transform, cx, cy, opacity } =
    properties[theme === ThemeOptions.dark ? 'dark' : 'light'];

  const svgContainerProps = useSpring({
    transform,
    config: properties.springConfig
  });
  const centerCircleProps = useSpring({ r, config: properties.springConfig });
  const maskedCircleProps = useSpring({
    cx,
    cy,
    config: properties.springConfig
  });
  const linesProps = useSpring({ opacity, config: properties.springConfig });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      onClick={themeToggle}
      style={{
        cursor: 'pointer',
        ...(svgContainerProps as any)
      }}
    >
      <mask id="myMask2">
        <rect x="0" y="3" width="100%" height="100%" fill="white" />
        <animated.circle style={maskedCircleProps as any} r="10" fill="black" />
      </mask>

      <animated.circle
        cx="12"
        cy="12"
        style={centerCircleProps as any}
        fill="currentColor"
        mask="url(#myMask2)"
      />
      <animated.g stroke="currentColor" style={linesProps as any}>
        <line x1="12" y1="1" x2="12" y2="3" />
        <line x1="12" y1="21" x2="12" y2="23" />
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
        <line x1="1" y1="12" x2="3" y2="12" />
        <line x1="21" y1="12" x2="23" y2="12" />
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
      </animated.g>
    </svg>
  );
};
