import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
`;

interface SpacerProps {
  height: number;
}
export const Spacer = styled.div<SpacerProps>`
  display: flex;
  height: ${(props) => (props.height ? `${props.height}px;` : '20px;')};
`;
