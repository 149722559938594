import { MainPageContentStrings } from '../mainPageContent';

export const EnglishMainPageTranslation: Record<MainPageContentStrings, string> = {
  landingPageTitle: "Hi, I'm Aria 🍂",
  landingPageSubtitle: 'Sometimes an author, an artist, or an analyst - but always an absurdist',
  underConstructionTitle: '🚧 Work always in progress! 🚧 ',
  underConstructionBlurb:
    "Come back from time to time! I swap out widgets every so often to test out tech or better reflect what I'm currently working on. You can check out any of my social links at the top right of your screen, or contact me with the form at the bottom!",
  checkOutMyBlog:
    'I write various forms of fiction, reviews, and tech articles from time to time. You can check out my blog here:',
  currentlyReading: "Check out what I've been reading recently",
  currentlyWorkingTitle: "Some tools I'm currently working with"
};
