import React, { useState } from 'react';

interface ContactFormProps {}
interface FormState {
  name: string;
  email: string;
  message: string;
}

export const ContactForm: React.FC<ContactFormProps> = () => {
  const [formState, setFormState] = useState<FormState>({ name: '', email: '', message: '' });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log('submitted form with data ', formState);
  };

  const updateName = (e: any) => {
    setFormState({ name: e.target.value, email: formState.email, message: formState.message });
  };
  const updateEmail = (e: any) => {
    setFormState({ name: formState.name, email: e.target.value, message: formState.message });
  };
  const updateMessage = (e: any) => {
    setFormState({ name: formState.name, email: formState.email, message: e.target.value });
  };

  return (
    <div style={{ display: 'block', justifyContent: 'center', width: 'auto' }}>
      <h3>Contact me:</h3>
      <form
        id="contact-form"
        onSubmit={handleSubmit.bind(this)}
        method="POST"
        style={{ width: 'inherit' }}
      >
        <div
          className="form-group"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={formState.name}
            onChange={updateName}
          />
        </div>
        <div
          className="form-group"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <label htmlFor="exampleInputEmail1">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            value={formState.email}
            onChange={updateEmail}
          />
        </div>
        <div
          className="form-group"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <label htmlFor="message">Message</label>
          <textarea
            className="form-control"
            rows={5}
            id="message"
            value={formState.message}
            onChange={updateMessage}
            style={{ resize: 'none' }}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
};
