import { useState } from 'react';

import { DarkModeContext, ThemeOptions, ThemeType } from '../contexts/DarkModeContext';

export const DarkModeProvider = ({ children }: any) => {
  const themeStorageKey = 'chaoticallyTheme';
  const [theme, setThemeState] = useState<ThemeType>(
    (localStorage.getItem(themeStorageKey) as ThemeType) || ThemeOptions.dark
  );

  const setTheme = (theme: ThemeType) => {
    setThemeState(theme);
    localStorage.setItem(themeStorageKey, theme);
  };

  return (
    <DarkModeContext.Provider value={{ theme, setTheme }}>{children}</DarkModeContext.Provider>
  );
};
