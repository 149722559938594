export const MainPageContent = {
  landingPageTitle: 'landingPageTitle',
  landingPageSubtitle: 'landingPageSubtitle',
  underConstructionTitle: 'underConstructionTitle',
  underConstructionBlurb: 'underConstructionBlurb',
  currentlyReading: 'currentlyReading',
  checkOutMyBlog: 'checkOutMyBlog',
  currentlyWorkingTitle: 'currentlyWorkingTitle'
} as const;
type MainPageKeys = keyof typeof MainPageContent;
export type MainPageContentStrings = (typeof MainPageContent)[MainPageKeys];
