import React from 'react';
import styled from 'styled-components';
import './ToggleSwitch.css';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/
interface ToggleSwitchProps {
  id: string;
  name: string;
  checked: boolean;
  onChange: Function;
  optionLabels?: string[];
  small?: boolean;
  disabled?: boolean;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  name,
  checked,
  onChange,
  optionLabels,
  small,
  disabled
}) => {
  function handleKeyPress(e: any) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  }

  return (
    <ToggleSwitchContainer className={'toggle-switch' + (small ? ' small-switch' : '')}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id ? (
        <label
          className="toggle-switch-label"
          htmlFor={id}
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e) => {
            handleKeyPress(e);
          }}
        >
          <span
            className={
              disabled ? 'toggle-switch-inner toggle-switch-disabled' : 'toggle-switch-inner'
            }
            data-yes={optionLabels && optionLabels[0]}
            data-no={optionLabels && optionLabels[1]}
            tabIndex={-1}
          />
          <span
            className={
              disabled ? 'toggle-switch-switch toggle-switch-disabled' : 'toggle-switch-switch'
            }
            tabIndex={-1}
          />
        </label>
      ) : null}
    </ToggleSwitchContainer>
  );
};

export default ToggleSwitch;

const ToggleSwitchContainer = styled.div`
  .toggle-switch {
    position: relative;
    margin-right: 10px;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    &-checkbox {
      display: none;
    }
    &-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 0 solid $#bbb;
      border-radius: 20px;
      margin: 0;
      &:focus {
        outline: none;
        > span {
          box-shadow: 0 0 2px 5px $ #ff0;
        }
      }
      > span:focus {
        outline: none;
      }
    }
    &-inner {
      display: block;
      width: 200%;
      vertical-align: middle;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
      font-size: 30px;
      &:before,
      &:after {
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        line-height: 34px;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        box-sizing: border-box;
      }
      &:before {
        content: attr(data-yes);
        text-transform: uppercase;
        padding-left: 10px;
        background-color: $#2f855a;
        color: $#fff;
      }
    }
    &-disabled {
      background-color: $#ddd;
      cursor: not-allowed;
      &:before {
        background-color: $#ddd;
        cursor: not-allowed;
      }
    }
    &-inner:after {
      content: attr(data-no);
      text-transform: uppercase;
      padding-right: 10px;
      background-color: #b3cee5;
      color: #;
      text-align: right;
    }
    &-switch {
      display: block;
      width: 24px;
      height: 24px;
      margin: 5px;
      background: $#fff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 40px;
      border: 0 solid $#bbb;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }
    &-checkbox:checked + &-label {
      .toggle-switch-inner {
        margin-left: 0;
      }
      .toggle-switch-switch {
        right: 0px;
      }
    }
    &.small-switch {
      width: 40px;
      .toggle-switch-inner {
        &:after,
        &:before {
          content: '';
          height: 20px;
          line-height: 20px;
        }
      }
      .toggle-switch-switch {
        width: 16px;
        right: 20px;
        margin: 2px;
      }
    }
    @media screen and (max-width: 991px) {
      transform: scale(0.9);
    }
    @media screen and (max-width: 767px) {
      transform: scale(0.825);
    }
    @media screen and (max-width: 575px) {
      transform: scale(0.75);
    }
  }
`;
