import React from 'react';
import './BookCard.css';

// CSS credit: "https://dribbble.com/YancyMin"
export const BookCard: React.FC<{}> = () => {
  return (
    <>
      <div className="box-out">
        <div className="book books-1"></div>
        <div className="book books-2"></div>
        <div className="book books-3"></div>
      </div>
    </>
  );
};

export default BookCard;
