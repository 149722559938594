import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { EnglishMainPageTranslation } from './locales/en';
import { FrenchMainPageTranslation } from './locales/fr';

const resources = {
  en: {
    main: EnglishMainPageTranslation
  },
  fr: {
    main: FrenchMainPageTranslation
  }
};

i18next.use(initReactI18next).init({
  resources,
  lng: 'en' //default language
});

export default i18next;
