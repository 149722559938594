import { createContext } from 'react';

export const ThemeOptions = {
  light: 'light',
  dark: 'dark'
} as const;
export type ThemeTypeKeys = keyof typeof ThemeOptions;
export type ThemeType = (typeof ThemeOptions)[ThemeTypeKeys];

interface DarkModeContextValue {
  theme: ThemeType;
  setTheme: Function;
}
export const DarkModeContext = createContext<DarkModeContextValue>({
  theme: ThemeOptions.light,
  setTheme: () => {}
});
