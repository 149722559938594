import { ThemeProvider } from 'styled-components';
import TopBar from './components/TopBar/TopBar';
import { useDarkMode } from './hooks/useDarkMode';
import { GlobalStyles } from './theme/globalStyles';
import { darkTheme, lightTheme } from './theme/theme';

//Import i18n.ts
import { MainContent } from './components/MainContent';
import './i18n/i18n';

function App() {
  const { theme } = useDarkMode();
  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <>
        <GlobalStyles />
        <TopBar />

        <MainContent />

        {/* <div style={{ height: '100vh', width: '100vw', position: 'fixed' }}>
            <BasicCanvasSample />
          </div> */}
      </>
    </ThemeProvider>
  );
}

export default App;
